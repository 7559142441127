import React, { useState } from "react";
import { bubble as Menu } from "react-burger-menu";

export function BubbleMenu() {
  const currentPage = "home";
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  return (
    <Menu isOpen={menuOpen} onStateChange={handleStateChange}>
      <BurgerMenuItem href="/" page={currentPage}>
        Home
      </BurgerMenuItem>
      <BurgerMenuItem
        href="#hotelSection"
        page={currentPage}
        toggleMenu={toggleMenu}
      >
        Venue
      </BurgerMenuItem>
      <BurgerMenuItem
        href="#rottnestparty"
        page={currentPage}
        toggleMenu={toggleMenu}
      >
        Rottnest Overnight Party
      </BurgerMenuItem>
      <BurgerMenuItem
        href="#workshopschedule"
        page={currentPage}
        toggleMenu={toggleMenu}
      >
        Workshop Schedule
      </BurgerMenuItem>
      <BurgerMenuItem
        href="#codeofconduct"
        page={currentPage}
        toggleMenu={toggleMenu}
      >
        AWAKE Code of Conduct
      </BurgerMenuItem>
      <BurgerMenuItem
        href="#contactus"
        page={currentPage}
        toggleMenu={toggleMenu}
      >
        Contact Us
      </BurgerMenuItem>
      <BurgerMenuItem
        href="https://events.humanitix.com/awake-festival-10th-edition"
        page={currentPage}
      >
        Book Now
      </BurgerMenuItem>
    </Menu>
  );
}

function BurgerMenuItem({ href, page, children, toggleMenu }) {
  const isCurrentPage = page === children.toLowerCase();

  const handleClick = (e) => {
    toggleMenu();
    // any other click logic you have can go here...
  };

  return (
    <a
      className={`menu-item ${isCurrentPage ? "selected-menu-item" : ""}`}
      href={href}
      onClick={handleClick}
    >
      {children}
    </a>
  );
}
