import { Rottnest } from "./Rottnest";
import { Hotel } from "./HotelInformation";
import { OurLineup } from "./Lineup";
import EmblaCarousel from "./EmblaCarousel";
import { useMediaQuery } from "react-responsive";
import "./Lineup.css";

export function MainPageBody() {
  return (
    <div className="main">
      <BookNowButton />
      <Rottnest />
      <Schedule />
      <OurLineup />
      <Hotel />
      <CodeOfConduct />
    </div>
  );
}

function BookNowButton() {
  return (
    <div>
      <a href="https://events.humanitix.com/awake-festival-10th-edition">
        <button className="awake-btn book-now-btn">BOOK NOW</button>
      </a>
    </div>
  );
}

function Schedule() {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div id="workshopschedule" className="schedule-container">
      <div className="section-title-blue">AWAKE Workshop Schedule</div>
      {isMobile ? (
        // Show carousel on mobile
        <div className="schedule-carousel">
          <EmblaCarousel
            slides={[
              "/schedulesplit01.jpg",
              "/schedulesplit02.jpg",
              "/schedulesplit03.jpg",
            ]}
            options={{ loop: true, auto: true }}
          />
        </div>
      ) : (
        // Show the full schedule image on larger screens
        <img
          src="/schedulefull.jpg"
          alt="AWAKE Schedule"
          className="schedule-image"
        />
      )}
    </div>
  );
}

function CodeOfConduct() {
  return (
    <div id="codeofconduct" className="code-of-conduct-container">
      <div className="section-title">AWAKE Code of Conduct </div>
      <div className="code-of-conduct-information">
        At AWAKE, we are committed to providing a safe and inclusive environment
        for all our guests. To ensure this, we expect all artists and attendees
        to adhere to the AWAKE Code of Conduct, which can be found below.
      </div>
      <a
        href={`${process.env.PUBLIC_URL}/pdf/awake_code_of_conduct.pdf`}
        download="CodeOfConduct.pdf"
      >
        <button className="awake-btn code-of-conduct-btn">
          Download Code of Conduct
        </button>
      </a>
    </div>
  );
}
